<template>
  <div class="relative">
    <h1 class="text-3xl sm:text-5xl md:text-7xl font-semibold absolute drop-shadow-lg font-display uppercase bottom-5 left-5 text-white">
      {{ templatevar.title }}
    </h1>
    <cgn-lazy-image :image-id="templatevar.background" image-aspect="16x5" image-width="1920" />
  </div>
</template>

<script lang="ts">
class Templatevars {
  title?: string
  background?: string
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
</script>
