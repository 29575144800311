import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://dnd.${config.devbase}`
config.prodURL = 'https://cms.dnd3dprint.com.au'
config.siteURL = 'https://dnd3dprint.com.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.group_args.image_aspect = '4x3'

config.isEcommerce = true
config.sentryDsn = 'https://283978b00c5c421992ec83679e57f63b@glitchtip.logger.jm1.au/8'

export { config }
