<template>
  <div class="relative">
    <cgn-breadcrumb :links="breadcrumbs" />
    <div class="pt-16 lg:pt-12">
      <h1
        class="flex flex-row justify-center gap-2 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl"
      >
        <div class="font-bold text-gray-700 dark:text-gray-300">
          Shop
        </div>
        <div v-if="!groupDetails.isRoot()" class="text-brand-500">
          {{ groupDetails.name }}
        </div>
      </h1>
      <div class="flex flex-col justify-center">
        <div class="prose-brand prose self-center pt-4 text-sm dark:prose-dark" v-html="groupDetails.content" />
      </div>
    </div>
    <div class="gap-10 px-4 pb-20 sm:px-6 lg:px-8 lg:pb-28">
      <div class="relative mx-auto max-w-7xl">
        <div class="mx-auto mt-10 grid max-w-lg gap-5 md:max-w-none md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <template v-if="childGroups.length > 0">
            <router-link
              v-for="childGroup in childGroups" :key="childGroup.url" :to="`/shop/${childGroup.url}`"
              class="flex flex-col overflow-hidden rounded-lg shadow-lg"
            >
              <cgn-lazy-image class="w-full bg-white" :image="childGroup.image" />
              <div class="flex flex-col gap-2 bg-white p-6 text-center dark:bg-gray-800">
                <div>
                  <p class="text-xl font-semibold text-gray-900 dark:text-gray-100">
                    {{ childGroup.name }}
                  </p>
                </div>
                <cgn-button color-brand fullwidth>
                  Shop {{ childGroup.name }}
                </cgn-button>
              </div>
            </router-link>
          </template>
          <template v-else>
            <product-grid :products="products" />
          </template>
        </div>
        <div class="flex grow justify-center py-5">
          <cgn-pagination :current-page="props.page" :page-count="num_pages" :group="props.group" url-prefix="shop" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { gql } from 'graphql-request'
import { SellProduct } from '~cognito/models/Sell/Product'
import { CognitoGroup } from '~cognito/models/Cognito/Group'
import { $axios } from '~cognito/plugins/axios'

const props = defineProps({
  group: {
    type: String,
    default: 'page',
  },
  page: {
    type: Number,
    default: 1,
  },
})
const products = ref<SellProduct[]>([])

const groupDetails = ref<CognitoGroup>(new CognitoGroup())
const parentGroups = ref<CognitoGroup[]>([])
const childGroups = ref<CognitoGroup[]>([])
const num_pages = ref(0)

const breadcrumbs = ref([{ name: 'Shop', url: '/shop' }])

async function loadProducts() {
  products.value = []
  num_pages.value = 1
  breadcrumbs.value = [{ name: 'Shop', url: '/shop' }]

  let group = props.group
  const page = props.page
  if (group === 'page') {
    group = ''
  }

  if (group) {
    // Need the group to get heading etc
    groupDetails.value = await new CognitoGroup().findByUrl('Sell\\Product', group)
  } else {
    groupDetails.value = await new CognitoGroup().getTopLevelGroup('Sell\\Product')
  }

  if (!groupDetails.value.isRoot()) {
    parentGroups.value = await groupDetails.value.getParents()
    parentGroups.value.forEach((parentGroup) => {
      if (parentGroup.isRoot()) {
        return
      }
      breadcrumbs.value.push({ name: parentGroup.name, url: parentGroup.url })
    })
    breadcrumbs.value.push({ name: groupDetails.value.name, url: group })
  }

  childGroups.value = await groupDetails.value.getChildren(1)

  if (!group) {
    // Do not load paginator on main /shop page
    products.value = []
    num_pages.value = 1
    return
  }

  const pageLength = 12

  const gqldata = await $axios.graphql(gql`query productByGroupQuery($group: String!, $first: Int, $offset: Int) {
    sellProductCount(byGroup: $group)
    sellProducts(byGroup: $group, first: $first, offset: $offset) {
      name
      slug: url
      blurb
      is_new
      is_on_special
      image(image_aspect: "4x3", image_width: 300) {
        url
        width
        height
      }
    }
  }`, {
    group,
    first: pageLength,
    offset: (page - 1) * pageLength,
  })
  products.value = new SellProduct().map(gqldata.sellProducts)
  num_pages.value = Math.ceil(gqldata.sellProductCount / pageLength)
}
watch(() => props, () => {
  loadProducts()
}, {
  deep: true,
})
onMounted(() => {
  loadProducts()
})
onServerPrefetch(async () => {
  await loadProducts()
})
</script>
