<template>
  <div class="relative">
    <div class="gap-10 px-4 pb-20 sm:px-6 lg:px-8 lg:pb-28">
      <div class="relative mx-auto max-w-7xl">
        <div class="mx-auto mt-10 grid max-w-lg gap-5 md:max-w-none md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <product-grid :products="products" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { gql } from 'graphql-request'
import { SellProduct } from '~cognito/models/Sell/Product'
import { $axios } from '~cognito/plugins/axios'

const products = ref<SellProduct[]>([])

async function loadProducts() {
  products.value = []

  const gqldata = await $axios.graphql(gql`query productByIsNewQuery {
    sellProducts(byIsNew: true, first: 4, offset: 0) {
      name
      slug: url
      blurb
      is_new
      is_on_special
      image(image_aspect: "4x3", image_width: 300) {
        url
        width
        height
      }
    }
  }`)

  products.value = new SellProduct().map(gqldata.sellProducts)
}
onMounted(() => {
  loadProducts()
})
onServerPrefetch(async () => {
  await loadProducts()
})
</script>
