<template>
  <div
    class="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 py-24 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28 gap-16"
  >
    <div class="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
      <div class="relative">
        <div class="z-10 relative">
          <div class>
            <h1 class="my-2 text-gray-800 dark:text-white font-bold text-2xl">
              Page not found
            </h1>
            <p
              class="my-2 text-gray-800 dark:text-white"
            >
              Please visit our homepage to get where you need to go.
            </p>
            <cgn-button color-brand extra-classes="px-8 py-[1rem]" class="pt-2" url="/">
              Take me home
            </cgn-button>
          </div>
        </div>
        <div class="absolute top-0 text-[12rem] sm:text-[15rem] leading-[11rem] font-bold text-white dark:text-darkbg-500 sm:left-20">
          404
        </div>
      </div>
    </div>
  </div>
</template>

<route lang="yaml">
name: not-found
meta:
  layout: 404
</route>
